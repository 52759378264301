

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.btnSubmit{
 
width: 100%;
}
.btnNewUser{
  width:100%;
}

.or-container {
  align-items: center;
  color: #ccc;
  display: flex;
  margin: 25px 0;
}
.line-separator {
  background-color: #ccc;
  flex-grow: 5;
  height: 1px;
}
.or-label {
  flex-grow: 1;
  margin: 0 15px;
  text-align: center;
}
.line-separator {
  background-color: #ccc;
  flex-grow: 5;
  height: 1px;
}
.btn-google {
  color: #545454;
  background-color: #ffffff;
  box-shadow: 0 1px 2px 1px #ddd;
}
.captchaWrapper{
  padding-left: 15px;
  float: right;
}
.licaption{
  background-color:lightseagreen !important;
  color:white;
  font-weight: bold;
}

/*////// main content //////*/
.jh-main-content{
  background-color: #f2f2f2;
}
.jh-inner-page-main-content{background-color: #fff;width: 100%;}
.jh-main-content .jh-main-content-block{
  padding: 5px;
}

/*////// End main content //////*/


.jh-widget-block{width: 100%;float: left;}


.jh-widget-blocks .col, 
.jh-widget-blocks .col-1, 
.jh-widget-blocks .col-10, 
.jh-widget-blocks .col-11, 
.jh-widget-blocks .col-12, 
.jh-widget-blocks .col-2, 
.jh-widget-blocks .col-3, 
.jh-widget-blocks .col-4, 
.jh-widget-blocks .col-5, 
.jh-widget-blocks .col-6, 
.jh-widget-blocks .col-7, 
.jh-widget-blocks .col-8, 
.jh-widget-blocks .col-9, 
.jh-widget-blocks .col-auto, 
.jh-widget-blocks .col-lg, 
.jh-widget-blocks .col-lg-1, 
.jh-widget-blocks .col-lg-10, 
.jh-widget-blocks .col-lg-11, 
.jh-widget-blocks .col-lg-12, 
.jh-widget-blocks .col-lg-2, 
.jh-widget-blocks .col-lg-3, 
.jh-widget-blocks .col-lg-4, 
.jh-widget-blocks .col-lg-5, 
.jh-widget-blocks .col-lg-6, 
.jh-widget-blocks .col-lg-7, 
.jh-widget-blocks .col-lg-8, 
.jh-widget-blocks .col-lg-9, 
.jh-widget-blocks .col-lg-auto, 
.jh-widget-blocks .col-md, 
.jh-widget-blocks .col-md-1, 
.jh-widget-blocks .col-md-10, 
.jh-widget-blocks .col-md-11, 
.jh-widget-blocks .col-md-12, 
.jh-widget-blocks .col-md-2, 
.jh-widget-blocks .col-md-3, 
.jh-widget-blocks .col-md-4, 
.jh-widget-blocks .col-md-5, 
.jh-widget-blocks .col-md-6, 
.jh-widget-blocks .col-md-7, 
.jh-widget-blocks .col-md-8, 
.jh-widget-blocks .col-md-9, 
.jh-widget-blocks .col-md-auto, 
.jh-widget-blocks .col-sm, 
.jh-widget-blocks .col-sm-1, 
.jh-widget-blocks .col-sm-10, 
.jh-widget-blocks .col-sm-11, 
.jh-widget-blocks .col-sm-12, 
.jh-widget-blocks .col-sm-2, 
.jh-widget-blocks .col-sm-3, 
.jh-widget-blocks .col-sm-4, 
.jh-widget-blocks .col-sm-5, 
.jh-widget-blocks .col-sm-6, 
.jh-widget-blocks .col-sm-7, 
.jh-widget-blocks .col-sm-8, 
.jh-widget-blocks .col-sm-9, 
.jh-widget-blocks .col-sm-auto, 
.jh-widget-blocks .col-xl, 
.jh-widget-blocks .col-xl-1, 
.jh-widget-blocks .col-xl-10, 
.jh-widget-blocks .col-xl-11, 
.jh-widget-blocks .col-xl-12, 
.jh-widget-blocks .col-xl-2, 
.jh-widget-blocks .col-xl-3, 
.jh-widget-blocks .col-xl-4, 
.jh-widget-blocks .col-xl-5, 
.jh-widget-blocks .col-xl-6, 
.jh-widget-blocks .col-xl-7, 
.jh-widget-blocks .col-xl-8, 
.jh-widget-blocks .col-xl-9, 
.jh-widget-blocks .col-xl-auto{padding-left: 0; padding-right: 10px;}






/*////// blinking text animation //////*/
.blink {
	-webkit-animation: blink 1s linear infinite;
	-moz-animation: blink 1s linear infinite;
	-ms-animation: blink 1s linear infinite;
	-o-animation: blink 1s linear infinite;
	 animation: blink 1s linear infinite;
}
@-webkit-keyframes blink {
	0% { color: #FF9933; }
	50% { color: #FF9933;}
	50.01% { color: #138808; }
	100% { color:#138808}
}
@-moz-keyframes blink {
	0% { color: #FF9933; }
	50% { color: #FF9933;}
	50.01% { color: #138808; }
	100% { color:#138808}
}
@-ms-keyframes blink {
	0% { color: #FF9933; }
	50% { color: #FF9933;}
	50.01% { color: #138808; }
	100% { color:#138808}
}
@-o-keyframes blink {
	0% { color: #FF9933; }
	50% { color: #FF9933;}
	50.01% { color: #138808; }
	100% { color:#138808}
}
@keyframes blink {
	0% { color: #FF9933; }
	50% { color: #FF9933;}
	50.01% { color: #138808; }
	100% { color:#138808}
}

.mobblink {
	-webkit-animation: blink 1s linear infinite;
	-moz-animation: blink 1s linear infinite;
	-ms-animation: blink 1s linear infinite;
	-o-animation: blink 1s linear infinite;
	 animation: blink 1s linear infinite;
}
@-webkit-keyframes mobblink {
	0% { color: #138808; }
	50% { color: #138808;}
	50.01% { color: #FF9933; }
	100% { color:#FF9933}
}
@-moz-keyframes mobblink {
	0% { color: #138808; }
	50% { color: #138808;}
	50.01% { color: #FF9933; }
	100% { color:#FF9933}
}
@-ms-keyframes mobblink {
	0% { color: #138808; }
	50% { color: #138808;}
	50.01% { color: #FF9933; }
	100% { color:#FF9933}
}
@-o-keyframes mobblink {
	0% { color: #138808; }
	50% { color: #138808;}
	50.01% { color: #FF9933; }
	100% { color:#FF9933}
}
@keyframes mobblink {
	0% { color: #138808; }
	50% { color: #138808;}
	50.01% { color: #FF9933; }
	100% { color:#FF9933}
}


@keyframes bg-color-pulse {
  0% {
    background-color: #138808
  }
  50% {
    background-color: #FF9933;
  }
  100% {
    background-color: #138808
  }
}

@keyframes mob-bg-color-pulse {
  0% {
    background-color: #FF9933
  }
  50% {
    background-color: #138808;
  }
  100% {
    background-color: #FF9933
  }
}

/*////// blinking text animation //////*/
.jh-yellow-main-content{position: relative;}
/*////// Tri Color Top //////*/
/* .tri-color-bar{} */
.tri-color-line{height: 2px;background-color: #fff;}
.tri-color-line-orange{background-color: #FF9933;}
.tri-color-line-green{background-color: #138808;}
/*////// End Tri Color Top //////*/

/*////// jh-city-weather //////*/
.jh-city-weather-block{
  width: 100%;
  float: left;
}
.jh-city-weather-block-inner{
  width: 100%;
  float: left;
   /* margin-top: 25px; */
    /* border: 1px solid purple; */
}
.jh-city-weather{
  width: calc(100% - 50px);
    float: left;
}
.jh-city-weather-icon{
  width: 50px;
    float: left;
}
.jh-city-weather a,
.jh-city-weather a:hover{ color: #82338d;}

.jh-city-block{width: auto;}
.jh-city-block-inner{
  width: auto;
  float: left;
  cursor: pointer;
  padding-right: 5px;
}
.jh-city-block .jh-city-block-icon{
  margin-right: 5px;
  font-size: 14px;
  line-height: 24px;
  float: left;  
}
.jh-city-block .jh-city-block-icon.fa-chevron-down{font-size: 11px; color: #333;}
.jh-city-block-city-name{color: #333;float: left;margin-right: 2px;line-height: 25px;}

.jh-weather-block{width: auto;padding-right: 8px;}
.jh-weather-block-inner{width: auto; float: left;}
.jh-weather-block-msg{
  width: auto;
  float: left;
  font-size: 12px;
  color: #0056b3;
  line-height: 26px;  
  text-decoration: underline;
}
/*////// end jh-city-weather //////*/
/*////// jh-header-search //////*/
.jh-header-search{width: 100%;margin: 0 auto;}
.jh-header-search input[type=text] {
  padding: 10px;
  font-size: 14px;
  border-color: #82338d;
  border-left-color: #f8f1f9;
  background-color: #f8f1f9;
  float: left;
  width: calc(100% - 90px);
  box-shadow: none;
  border-radius: 0 !important;
  height: 30px;
}

.jh-header-search .search-btn {
  float: left;
  width: 50px;
  min-height:30px;
  max-height: 30px;
  background: #82338d;
  color: #fff;
  font-size: 14px;
  border: 1px solid #82338d;
  border-left: none;
  cursor: pointer;
  box-shadow: none;
  display: flex;
  align-items: center;
  justify-content: center;
  border-top-right-radius: 0.25rem!important;
border-bottom-right-radius: 0.25rem!important;

  /* border-radius: 0 3px 3px 0 !important; */
}

.jh-header-search .search-btn:hover {
  background: #782b83;
  color: #fff;
}
.jh-header-search .search-btn i{ 
  font-size: 20px;
    font-weight: 100;
    vertical-align: middle;
    /* margin-top: 5px; */
	color:white !important;
  display: flex;
  align-items: center;
  justify-content: center;
}
.jh-header-search::after {
  content: "";
  clear: both;
  display: table;
}
.jh-header-search input[type=text]::placeholder {
  color: #999;

}
.jh-header-search-icon .input-group-text,
.jh-header-search-icon .input-group-text a{
    border-color: #82338d;
    border-right-color: #f8f1f9;
    background-color: #f8f1f9;
    color: #82338d;
    
}
.jh-header-search-icon .input-group-text .fa-search{font-weight: bold;}


/*jh-header-app-download*/
.jh-header-app-download{
  width: auto;
  float: left;
  margin: 0px 0px 4px;
  height: 21px;
  border-radius: 2px;
}
.jh-header-app-block{width:auto; float: left;color:#333;}
.JaiAppDownloadTXT{
  min-width: 140px;
  max-width: 140px;
  /* border: 1px solid #f93; */
  /*border: 1px solid purple;
  border-radius: 11px;
  height: 20px;
  padding-left: 5px;*/
  padding: 0 10px;
  border-radius: 0.25rem;
  box-sizing: border-box;
}
.jh-header-app-block.JaiAppDownloadTXT {
  /*color: #FF9933;
  font-family: 'Roboto-Bold';
  width: calc(100% - 12px);
  line-height: 19px;*/
  /*width: calc(100% - 12px);*/
  
  animation-name: mob-bg-color-pulse;
  animation-duration: 2s;
  animation-iteration-count: infinite;
  min-height: 25px;
  display: flex;
    align-items: center;
}
.jh-header-app-block.JaiFranchiseTXT{
  /* margin-right: 55px; */
  animation-name: bg-color-pulse;
  animation-duration: 2s;
  animation-iteration-count: infinite;
}
.JaiFranchiseTXT{
  min-width: 140px;
  padding: 0 10px;
  border-radius: 0.25rem;
  box-sizing: border-box;
  min-height: 25px;
  display: flex;
    align-items: center;
}
.jh-franchise-text{
	color:white !important;
}
.jh-mob-text{
	overflow:unset !important;
}
.jh-search-icon{
	color:purple !important;
}
.jh-header-app-icon,
.jh-header-app-text{width: auto;float: left;line-height: 20px;}
.jh-header-app-icon{
  /* border: 1px solid #82338d;
    border-radius: 50%;
    width: 25px;
    height: 25px;
    text-align: center; */
    /* background-color: #fff; */
    margin-right: 5px;
}
.jh-header-app-icon i{
  font-size: 14px;
  color: #fff;
  margin-top: 1px;
  transition: all .5s;
}

.jh-header-app-text{
  /* width: calc(100% - 22px); */
  color: #fff;
  font-size: 12px;
  overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
}
/* .jh-header-app-block.JaiAppDownloadTXT .jh-header-app-text{
  width: calc(100% - 16px);
} */
/*jh-header-app-download*/
/*////// End jh-header-search //////*/

/*////// news thumbnails //////*/
.news-thumbnail-block{
  background-color: #fff;
  padding:0 5px 5px;
  box-shadow: 0 0 1px rgba(0,0,0,.2);
  border:1px solid #f93;
  border-radius: 2px;
  /* height:50px !important; */
  overflow: hidden;
  width: 100%;
}
.news-thumbnails{
  width: 55px;
  height: 48px;
    /* border: 1px solid #82338d; */
    float: left;
    margin-right: 5px;
    border-radius: 2px;
    overflow: hidden;
    background-color: #fff;
}
.news-thumbnails a{
  margin-top: 5px;
  float: left;
}

.news-thumbnails-static{
  float: left;
    margin-right: 5px;
    border-radius: 2px;
    overflow: hidden;
    background-color: #fff;
    margin-top: 5px;
}
.news-thumbnails-static a{
  width: 100%;
}
.news-thumbnails-static img{
  height: 15px;
}
/*////// End news thumbnails //////*/
/*////// carousel notification //////*/
.jh-carousel-notification{}
.jh-carousel-notification-inner{width: 100%;float: left;}
.jh-carousel-notification-img{width: 110px;float: left;}
.jh-carousel-notification-list{
  width: calc(100% - 110px);
  float: left;
  /* height: 40px; */
  overflow: hidden;
  color: #fff;
  line-height: 16px;
  padding: 7px 10px;
  font-size: 12px;  
}
.marquee .carousel-inner{
  background-color: #82338d;
}
/*////// End carousel notification //////*/

/*////// footer logo //////*/
.logo-footer{
  width: 75px;
  margin-right: 10px;
  float: left;
  margin-top: 2px;
}
.logo-footer-description{
  width: calc(100% - 85px);
  float: left;
}








.bg-dark-purple,
.bg-dark-purple .navbar-collapse{
  background-color: #82338d !important;
}
.bg-dark-purple .nav-item{
 border-right: 1px solid #9e46ab; 
 align-items: center;
 justify-content: center;
}
.bg-dark-purple.navbar-dark .navbar-nav .nav-link{
  font-size: 14px;
  line-height: 24px;
  color: rgba(255,255,255,1) !important;  
  padding-right: 1em;
  padding-left: 1em;
  
}
.bg-dark-purple.navbar-dark .navbar-nav .nav-link:hover,
.bg-dark-purple.navbar-dark .navbar-nav .active .nav-link,
.bg-dark-purple.navbar-dark .navbar-nav .mn-hvr.show>.nav-link{color: #fff !important;}
.bg-dark-purple .nav-item:focus,
.bg-dark-purple .nav-item:hover,
.bg-dark-purple .nav-item.active{
  background-color: #82338d;
}
.bg-dark-purple .nav-item.active{background-color: #FF9933;}
.bg-dark-purple .dropdown-menu.show{
  background-color: #82338d;
    border-radius: 2px;

}
.bg-dark-purple .dropdown-menu .dropdown-item{
  background-color: #82338d !important;
  /* border-bottom: 4px solid #1a1a1a; */
}
.bg-dark-purple .dropdown-menu .dropdown-item:hover{
  /* border-color: #ff9933; */
}

.mn-hvr,
.bg-dark-purple .dropdown-menu .dropdown-item {
  display: inline-block;
  vertical-align: middle;
  -webkit-transform: perspective(1px) translateZ(0);
  transform: perspective(1px) translateZ(0);
  box-shadow: 0 0 1px rgba(0, 0, 0, 0);
  position: relative;
  /* overflow: hidden; */
}
.mn-hvr:before,
.bg-dark-purple .dropdown-menu .dropdown-item::before {
  content: "";
  position: absolute;
  z-index: -1;
  left: 51%;
  right: 51%;
  bottom: 0;
  background:transparent;
  height: 40px;
  border:3px solid #82338d;
  border-width: 0 3px;
  -webkit-transition-property: left, right;
  transition-property: left, right;
  -webkit-transition-duration: 0.3s;
  transition-duration: 0.3s;
  -webkit-transition-timing-function: ease-out;
  transition-timing-function: ease-out;
}
.mn-hvr:hover:before, 
.mn-hvr:focus:before, 
.mn-hvr:active:before,
.bg-dark-purple .active.mn-hvr:before,
.bg-dark-purple .dropdown-menu .dropdown-item:hover:before {
  left: 0;
  right: 0;
  color: #f8c6ff !important;
  border-color: #FF9933;
}
/*/////////// End Horizontal nav bar ////////////*/


/*////// right-thumbnail-banner //////*/
.right-thumbnail-banner{
  width: 100%;
}
/*////// right-thumbnail-banner //////*/


/* ////// jh-nav-tabs //////*/
.jh-nav-tabs{
  margin: 10px;
  border-bottom: none !important;
}
.jh-nav-tabs .nav-item{
  margin-bottom: 0 !important;
}
.jh-nav-tabs .nav-item .nav-link{
  border-radius: 2px;
    border: 1px solid #3f4c6b;
    margin-right: 10px;
    padding-top: 6px;
    padding-bottom: 6px;
    color: #3f4c6b;
}
.jh-nav-tabs .nav-item .nav-link.active,
.jh-nav-tabs .nav-item .nav-link:hover{
  border-radius: 2px;
  border-color: #3f4c6b;
  background-color: #3f4c6b;
  color: #fff;
}
.jh-nav-tabs-content{
  width: 100%;
  float: left;
  margin: 5px 0 10px;
}
.jh-nav-tabs-item{
  width: 100%;
  float: left;
  
}
.jh-nav-tabs-item-link{
  width: 100%;
  float: left;
}
.jh-nav-tabs-item-link i{
  float: left;
  line-height: 16px;
  padding: 5px 0;
}
.jh-nav-tabs-item-link .jh-nav-tabs-item-text{
  width: calc(100% - 20px);
  float: left;
  margin-left: 5px;
  padding: 5px 0;
}
.jh-nav-tabs-item-link:hover{
  background-color: #e8eefd;
  
}
.jh-nav-tabs-item-link:hover *{
  color: #3f4c6b;
}
.jh-nav-tabs-item-link:hover i{
  color: #138808;
}
/* ----- top header CSS ----- */
.jh-city-weather .jh-city-block{
/* background: #0288d1; */
padding: 0px 10px;
max-height: 35px;
min-height: 35px;
border-radius: 0.25rem;
margin-bottom: 5px;
}

.jh-city-weather{
  width: 100%;
}
.banner-add-container .top-header-widget-banner{
min-height: 84px;
}
.jh-main-content-block .jh-news-thumbnail-block, .jh-main-content-block .marquee {
border-top:3px solid #82338d;
}
.newlogowrapper [class$="newslogoinner"]{
  background-color:  #fff;
  border:2px solid #82338d;
  border-radius: 0.25rem;
  display: flex;
  align-items: center;
  justify-content: center;
  box-shadow: rgb(0 0 0 / 20%) 0px 0px 5px 0px, rgb(0 0 0 / 20%) 0px 0px 1px 0px;
}
.newlogowrapper [class$="newslogoinner"]:hover{
  background-color: transparent;
  border:2px solid #82338d;
  box-shadow: rgb(0 0 0 / 80%) 0px 0px 5px 0px, rgb(0 0 0 / 80%) 0px 0px 1px 0px;
}
.newlogowrapper .newslogo{
  margin-top: unset;
}
.app-container{
  flex:0 0 9% !important;
  max-width: 9% !important;
}
.search-container{
  flex:0 0 41% !important;
  max-width: 41% !important;
  padding:0px 5px 0px !important
}
@media only screen and (min-width:1250px) and (max-width:1599px){
  .app-container{
    flex:0 0 12% !important;
    max-width: 12% !important;
  }
  .search-container{
    flex:0 0 38% !important;
    max-width: 38% !important;
    padding:0px 5px 0px !important
  }
}
@media only screen and (min-width:1025px) and (max-width:1249px){
  .app-container{
    flex:0 0 14% !important;
    max-width: 14% !important;
  }
  .search-container{
    flex:0 0 36% !important;
    max-width: 36% !important;
    padding:0px 5px 0px !important
  }
  .JaiFranchiseTXT,.JaiAppDownloadTXT{
    min-width: 143px;
    max-width: 143px;
    
  }
}
@media only screen and (min-width:767px) and (max-width:1023px){
  .app-container{
    flex:0 0 16% !important;
    max-width: 16% !important;
  }
  .search-container{
    flex:0 0 34% !important;
    max-width: 34% !important;
    padding:0px 5px 0px !important
  }  
  .banner-add-container{
    flex:0 0 41.6% !important;
    max-width: 41.6% !important;
  }
  .jh-carousel-notification-list{
    padding:2px;
  }
  .JaiFranchiseTXT,.JaiAppDownloadTXT{
    min-width: 143px;
    max-width: 143px;
    
  }
}
@media only screen and (min-width:769px) and (max-width:900px){
  .app-container{
    flex:0 0 19% !important;
    max-width: 19% !important;
  }
  .search-container{
    flex:0 0 35.5% !important;
    max-width: 35.5% !important;
    padding:0px 5px 0px !important
  }  
  .banner-add-container{
    flex:0 0 37% !important;
    max-width: 37% !important;
  }
  .jh-carousel-notification-list{
    padding:2px;
  }
  .JaiFranchiseTXT,.JaiAppDownloadTXT{
    min-width: 143px;
    max-width: 143px;
    
  }
}
/* ----- top header CSS ----- */
